import React from "react";
import { Link } from "react-router-dom";
import Intro from "../../components/intro/Intro";
import Pictures from "../../components/pictures/Pictures";

import apricot from "./pictures/apricot.webp";
import baylee from "./pictures/baylee.webp";
import britain from "./pictures/britain.webp";
import capone from "./pictures/capone.webp";
import christ from "./pictures/christ.webp";
import cover from "./pictures/cover.webp";
import earrings from "./pictures/earrings.webp";
import egg from "./pictures/egg.webp";
import elsa from "./pictures/elsa.webp";
import gaten from "./pictures/gaten.webp";
import hackpack from "./pictures/hackpack.webp";
import halo from "./pictures/halo.webp";
import hulk from "./pictures/hulk.webp";
import jesus from "./pictures/jesus.webp";
import mantle from "./pictures/mantle.webp";
import olaf from "./pictures/olaf.webp";
import pearl from "./pictures/pearl.webp";
import pig from "./pictures/pig.webp";
import potter from "./pictures/potter.webp";
import releafer from "./pictures/releafer.webp";
import squidslippers from "./pictures/squidslippers.webp";
import squidward from "./pictures/squidward.webp";

const Art = () => {
  const pictures = [
    {
      src: potter,
      alt: "harry potter and voldemort",
      description:
        "i made this for fun and gave it away to a stranger on the internet.",
    },
    {
      src: britain,
      alt: "a husband and wife looking at each other",
      description:
        "a friend of mine commissioned this wood burning as a christmas gift.",
    },
    {
      src: halo,
      alt: "master chief",
      description: "this commission was one of my earliest wood burnings.",
    },
    {
      src: jesus,
      alt: "jesus",
      description: "i made this as a gift for a family member.",
    },
    {
      src: elsa,
      alt: "elsa",
      description:
        "i made this for fun and gave it away to a stranger on the internet.",
    },
    {
      src: cover,
      alt: "wedding guest book cover",
      description: "a wedding guest book cover a friend commissioned from me.",
    },
    {
      src: gaten,
      alt: "gaten matarazzo",
      description:
        "shoutout to when 'stranger things' was big and i made this.",
    },
    {
      src: capone,
      alt: "capone",
      description: "a wood burning i made to honor my friend's late dog.",
    },
    {
      src: squidslippers,
      alt: "squidslippers",
      description: "my squidslippers logo! i love this little guy!",
    },
    {
      src: hackpack,
      alt: "an abstract infinity symbol with an 'H' hidden inside.",
      description: "the hackpack logo... one of my all-time favorites!",
    },
    {
      src: mantle,
      alt: "m with a circle over it",
      description: "the mantle logo. sleek. refined. calculated.",
    },
    {
      src: releafer,
      alt: "three leaves sprouting from a center point",
      description: "the releafer logo.",
    },
    {
      src: egg,
      alt: "egg",
      description: "a hyper-realistic egg i drew using procreate.",
    },
    {
      src: apricot,
      alt: "apricot",
      description: "a hyper-realistic apricot i drew using procreate.",
    },
    {
      src: pearl,
      alt: "pearl",
      description: "a hyper-realistic pearl i drew using procreate.",
    },
    {
      src: earrings,
      alt: "earrings",
      description: "some elements of harmony earrings i made for emma.",
    },
    {
      src: pig,
      alt: "pig",
      description: "an acrylic painting i made as a commission.",
    },
    {
      src: baylee,
      alt: "baylee",
      description: "a drawing i made for mrs. utah america herself.",
    },
    {
      src: olaf,
      alt: "olaf",
      description:
        "yeah, that's an actual snowman! (except i did print the eyes.)",
    },
    {
      src: christ,
      alt: "the living christ",
      description: 'an edit i made of "the living christ".',
    },
    {
      src: squidward,
      alt: "squidward",
      description:
        '"bold and brash" or "belongs in the trash"? (real ones know.)',
    },
    {
      src: hulk,
      alt: "the hulk",
      description:
        "a pumpkin i carved around 10 years ago at this point! time flies.",
    },
  ];

  return (
    <div className="main">
      <Intro
        primary="I 🤍 ART"
        secondary="i make, perform, sell, and give away all kinds of art: from wood
                burning, logo design, and digital art to painting, sculpting,
                drawing, and more—you name it, i've probably tried it! if you
                want to browse my full portfolio and buy some art..."
        tertiary="you're in the right place."
        button={
          <Link className="link" to={"/sweepstakes/live"}>
            GET WIRE ART
          </Link>
        }
      />
      <div className="column p01">
        <strong>try clicking on one!</strong>
        <Pictures pictures={pictures} />
      </div>
    </div>
  );
};

export default Art;
