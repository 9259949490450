import React from "react";
import Intro from "../../../components/intro/Intro";

const ColoredPaincil = () => {
  return (
    <div className="main">
      <Intro
        primary="COLORED PAINCIL"
        secondary="if you want a self-defense device that can go with you everywhere, does some serious damage, and looks good doing it..."
        tertiary="you're in the right place."
      />
      <div className="column p01">
        <h2>FUN FOR EVERYBODY</h2>
        <i className="o64" style={{ marginTop: "-1.5rem" }}>
          (ALMOST)
        </i>
        <p className="o64">
          the colored paincil is a branch of strong wood (oak) that i carve and
          paint to look like a larger-than-life colored pencil. it looks fun and
          playful, but this self-defense device packs quite a punch! check out
          some of the things that make it special:
        </p>
        <div className="columns">
          <div className="column">
            <h3>PROTECT YOUR FIST</h3>
            <p className="o64">
              punching can be dangerous. even with good form, it can be easy to
              hurt—and even break—your hand. the colored paincil reinforces your
              fist, reducing the risk of injury!
            </p>
            <h3>TAKE IT EVERYWHERE</h3>
            <p className="o64">
              it's small enough to fit in your pocket, purse, waistpack, duffel
              bag, backpack, glove box, or anywhere else. and because it's made
              out of wood (and looks so cute), it makes it past security, too,
              so you really can take it anywhere!
            </p>
          </div>
          <div className="column">
            <h3>STRENGTHEN YOUR STRIKES</h3>
            <p className="o64">
              the extra reinforcement the colored paincil provides actually
              makes your punches stronger, too. and striking with the tip does
              serious damage! (seriously, use responsibly!)
            </p>
            <h3>LOOK ADORABLE</h3>
            <p className="o64">
              it really looks like a colored pencil! and it comes in a variety
              of super cute colors.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ColoredPaincil;
