import React from "react";
import css from "./Pictures.module.css";
import arrow from "./arrow.png";
import x from "./x.png";

const Overlay = (props) => {
  let { index, pictures, setIndex } = props;
  const { src, alt, description } = pictures[index];

  const changeDirection = (e, direction) => {
    e.stopPropagation();
    switch (direction) {
      case "left":
        if (index > 0) {
          setIndex((previous) => {
            return previous - 1;
          });
        } else {
          setIndex(pictures.length - 1);
        }
        break;
      case "right":
        if (index < pictures.length - 1) {
          setIndex((previous) => {
            return previous + 1;
          });
        } else {
          setIndex(0);
        }
        break;
      default:
        break;
    }
  };

  return (
    <div className={css.overlay} onClick={() => setIndex(null)}>
      <img className={css.image} src={src} alt={alt} />
      <p>{description}</p>
      <div className={css.buttons}>
        <button
          className={css.button}
          onClick={(e) => {
            changeDirection(e, "left");
          }}
        >
          <img className={css.icon} src={arrow} alt="<" />
        </button>
        <button
          onClick={(e) => {
            e.stopPropagation();
            setIndex(null);
          }}
          className={css.button}
        >
          <img className={css.icon} src={x} alt="x" />
        </button>
        <button
          className={css.button}
          onClick={(e) => {
            changeDirection(e, "right");
          }}
        >
          <img
            className={css.icon}
            style={{ transform: "rotate(180deg)" }}
            src={arrow}
            alt="<"
          />
        </button>
      </div>
    </div>
  );
};

export default Overlay;
