import React, { useState } from "react";
import css from "./Accordion.module.css";

const Accordion = ({ children, open, soon, title }) => {
  const [showMore, setShowMore] = useState(open ? true : false);

  const toggleShowMore = () => {
    setShowMore((old) => !old);
  };

  return (
    <div className={`${css.main} ${soon ? css.disabled : ""}`}>
      <button className={css.button} disabled={soon} onClick={toggleShowMore}>
        <h3>{title}</h3>
        {soon ? (
          <strong>soon!</strong>
        ) : (
          <div className={`${css.caret} ${showMore && css.caret_open}`} />
        )}
      </button>
      {showMore && <div className={css.more}>{children}</div>}
    </div>
  );
};

export default Accordion;
