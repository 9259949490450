import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { CartContextProvider } from "./context/cart";
import { BrowserRouter } from "react-router-dom";
import { NotificationsContextProvider } from "./context/notifications";
import { IAMContextProvider } from "./context/iam";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <CartContextProvider>
      <IAMContextProvider>
        <NotificationsContextProvider>
          <App />
        </NotificationsContextProvider>
      </IAMContextProvider>
    </CartContextProvider>
  </BrowserRouter>
);
