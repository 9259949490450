import React, { useContext, useEffect } from "react";
import NotificationsContext from "./context/notifications";
import "./App.css";
import Header from "./components/header/Header";
import Cart from "./components/cart/Cart";
import CartPage from "./pages/cart/Cart";
import Footer from "./components/footer/Footer";
import IAMContext from "./context/iam";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import Home from "./pages/home/Home";
import Manage from "./pages/admin/live/Manage";
import Login from "./pages/admin/login/Login";
import Dashboard from "./pages/home/Dashboard";
import Art from "./pages/art/Art";
import Photography from "./pages/photography/Photography";
import PrivacyPolicy from "./pages/privacy_policy/PrivacyPolicy";
import Checkout from "./pages/checkout/Checkout";
import Order from "./pages/orders/order/Order";
import WireArt from "./pages/shop/wire_art/WireArt";
import SocialMedia from "./pages/social_media/SocialMedia";
import SoftwareEngineering from "./pages/software_engineering/SoftwareEngineering";
import LiveSweepstakes from "./pages/sweepstakes/LiveSweepstakes";
import Winner from "./pages/sweepstakes/Winner";
import View from "./pages/admin/live/View";
import Shop from "./pages/shop/Shop";
import ColoredPaincil from "./pages/shop/colored_paincil/ColoredPaincil";

function App() {
  const { array } = useContext(NotificationsContext);
  const { token } = useContext(IAMContext);
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  let adminLogin, adminDashboard, manageLive, viewLive;

  if (token) {
    adminDashboard = <Dashboard />;
    adminLogin = <Navigate replace to="/admin/dashboard" />;
    manageLive = <Manage />;
    viewLive = <View />;
  } else {
    adminDashboard = <Navigate replace to="/admin/login" />;
    adminLogin = <Login />;
    manageLive = <Navigate replace to="/admin/login" />;
    viewLive = <Navigate replace to="/admin/login" />;
  }

  return (
    <div className="App">
      {array}
      <Header />
      <Cart />
      <Routes>
        <Route exact path="/" element={<Home />} />

        <Route path="/admin/dashboard" element={adminDashboard} />
        <Route path="/admin/live/manage" element={manageLive} />
        <Route path="/admin/live/view" element={viewLive} />
        <Route path="/admin/login" element={adminLogin} />

        <Route path="/art" element={<Art />} />
        <Route path="/photography" element={<Photography />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />

        <Route path="/shop" element={<Shop />} />
        <Route path="/shop/cart" element={<CartPage />} />
        <Route path="/shop/checkout" element={<Checkout />} />
        <Route path="/shop/order/:id" element={<Order />} />
        <Route path="/shop/colored-paincil" element={<ColoredPaincil />} />
        <Route path="/shop/wire-art" element={<WireArt />} />

        <Route path="/social-media" element={<SocialMedia />} />
        <Route path="/software-engineering" element={<SoftwareEngineering />} />

        <Route path="/sweepstakes/live" element={<LiveSweepstakes />} />
        <Route path="/sweepstakes/winner/:id" element={<Winner />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
