import React, { useState } from "react";
import css from "./Pictures.module.css";
import Overlay from "./Overlay";

const Pictures = (props) => {
  const { pictures } = props;
  const [index, setIndex] = useState(null);

  return (
    <div className={css.pictures} style={props.style}>
      {index !== null && (
        <Overlay pictures={pictures} index={index} setIndex={setIndex} />
      )}
      {pictures.map((picture, index) =>
        picture.src && picture.alt ? (
          <img
            loading="lazy"
            key={index}
            className={css.picture}
            src={picture.src}
            alt={picture.alt}
            index={index}
            onClick={() => setIndex(index)}
          />
        ) : (
          <div className={css.picture} style={{ background: "red" }} />
        )
      )}
    </div>
  );
};

export default Pictures;
