import React from "react";
import css from "./SocialMedia.module.css";

const Platform = (props) => {
  const { platform } = props;
  const { url, img } = platform;
  const { src, alt } = img;

  return (
    <a
      href={url}
      target="_blank"
      rel="noreferrer noopener"
      className={css.platform}
    >
      <img loading="lazy" className={css.icon} src={src} alt={alt} />
    </a>
  );
};

export default Platform;
