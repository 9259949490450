import React from "react";
import Intro from "../../components/intro/Intro";
import Pictures from "../../components/pictures/Pictures";

import doctor from "./pictures/doctor.webp";
import bears from "./pictures/bears.webp";
import birds from "./pictures/birds.webp";
import confetti from "./pictures/confetti.webp";
import cool from "./pictures/cool.webp";
import corn from "./pictures/corn.webp";
import clouds1 from "./pictures/clouds1.webp";
import clouds2 from "./pictures/clouds2.webp";
import clouds3 from "./pictures/clouds3.webp";
import clouds4 from "./pictures/clouds4.webp";
import dolf from "./pictures/dolf.webp";
import donuts from "./pictures/donuts.webp";
import eevee from "./pictures/eevee.webp";
import emma from "./pictures/emma.webp";
import feet from "./pictures/feet.webp";
import flower from "./pictures/flower.webp";
import flowers from "./pictures/flowers.webp";
import harry from "./pictures/harry.webp";
import jonah from "./pictures/jonah.webp";
import maple from "./pictures/maple.webp";
import max from "./pictures/max.webp";
import meat from "./pictures/meat.webp";
import parents from "./pictures/parents.webp";
import raja from "./pictures/raja.webp";
import sleep from "./pictures/sleep.webp";
import wallflowers from "./pictures/wallflowers.webp";

const Photography = () => {
  const pictures = [
    {
      src: corn,
      alt: "anika standing in front of corn.",
      description: "look how cute my wife is!",
    },
    {
      src: sleep,
      alt: "anika sleeping.",
      description: "she really just looks like that when she's sleeping. wow.",
    },
    {
      src: doctor,
      alt: "anika on a doctor table",
      description:
        "proof that anika looks good at all times—even in an emergency.",
    },
    {
      src: cool,
      alt: "anika wearing sunglasses",
      description: "the weather is ~cool~ but my wife is ~hot~.",
    },
    {
      src: clouds1,
      alt: "clouds",
      description: "(the sky is the artist here; i just took the picture.)",
    },
    {
      src: clouds2,
      alt: "clouds",
      description: "(the sky is the artist here; i just took the picture.)",
    },
    {
      src: clouds3,
      alt: "clouds",
      description: "(the sky is the artist here; i just took the picture.)",
    },
    {
      src: clouds4,
      alt: "clouds",
      description: "(the sky is the artist here; i just took the picture.)",
    },
    {
      src: max,
      alt: "a brother and sister hugging",
      description:
        "taken mere moments before emma left on her mission to rome, italy.",
    },
    {
      src: eevee,
      alt: "a dog lying on a couch",
      description: "it's eevee baby!",
    },
    {
      src: jonah,
      alt: "a brother and sister hugging",
      description:
        "taken mere moments before emma left on her mission to rome, italy.",
    },
    {
      src: maple,
      alt: "a dog sitting on the sidewalk",
      description: "it's maple baby!",
    },
    {
      src: wallflowers,
      alt: "flowers in vases hanging from a white wall.",
      description: "the perks of being a what now?",
    },
    {
      src: flower,
      alt: "a bright orange flower on a black background",
      description:
        "this gorgeous flower is planted in front of our favorite coffee shop.",
    },
    {
      src: raja,
      alt: "red, white, and blue betta fish",
      description:
        "anika and i love betta fish. everyone, this is raja. raja, everyone.",
    },
    {
      src: flowers,
      alt: "close-up shot of flowers",
      description: "(i didn't even edit this one!)",
    },
    {
      src: bears,
      alt: "three young children in bear costumes",
      description: "these kids are so cute and an absolute blast to be around.",
    },
    {
      src: emma,
      alt: "emma",
      description: "a candid pic i got of emma on a random sunday afternoon.",
    },
    {
      src: dolf,
      alt: "two people celebrating",
      description:
        "our dad is good at disc golf... the rest of us are moral support.",
    },
    {
      src: parents,
      alt: "parents",
      description: "my mom and dad!",
    },

    {
      src: meat,
      alt: "diced steak in a pan",
      description:
        "steak: the breakfast of champion. (it's me; i'm the champion.)",
    },
    {
      src: feet,
      alt: "feet prints in sand",
      description:
        "getting a picture like this is NOT easy, i'll tell you that much.",
    },
    {
      src: harry,
      alt: "harry mack in concert",
      description:
        "this was one of the best concerts—and most impressive things—i've ever witnessed.",
    },
    {
      src: birds,
      alt: "two shadows raising their middle fingers",
      description: "a typical jake and emma greeting.",
    },
    {
      src: confetti,
      alt: "a close-up of multicolored streamers that were dangling from the ceiling and blowing in the wind.",
      description: "party time!",
    },
    {
      src: donuts,
      alt: "close-up shot of colorful donuts",
      description: "our favorite donut shop sells the cutest little treats.",
    },
  ];

  return (
    <div className="main">
      <Intro
        primary="SAY CHEEEEESE"
        secondary="i'm not a professional or anything, but i do have a nice camera, a
            basic understanding of photography, a lot of free time, and a few
            neat pictures to show for it."
      />
      <div className="column p01">
        <strong>try clicking on one!</strong>
        <Pictures pictures={pictures} />
      </div>
    </div>
  );
};

export default Photography;
