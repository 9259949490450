import React from "react";
import css from "./Intro.module.css";
import Carousel from "../carousel/Carousel";
import me1 from "./pictures/me1.png";
import me2 from "./pictures/me2.png";
import me3 from "./pictures/me3.png";
import me4 from "./pictures/me4.png";
import me5 from "./pictures/me5.png";
import me6 from "./pictures/me6.png";

const Intro = (props) => {
  const { button, primary, secondary, tertiary } = props;

  const defaultPics = [
    {
      src: me1,
      alt: "me",
    },
    {
      src: me2,
      alt: "me",
    },
    {
      src: me3,
      alt: "me",
    },
    {
      src: me4,
      alt: "me",
    },
    {
      src: me5,
      alt: "me",
    },
    {
      src: me6,
      alt: "me",
    },
  ];

  return (
    <div className={css.intro}>
      <div className={css.left}>
        {primary && <h1 className={css.primary}>{primary}</h1>}
        {secondary && (
          <p style={{ padding: 0 }} className="o64">
            {secondary}
          </p>
        )}
        {tertiary && <p style={{ padding: 0 }}>{tertiary}</p>}
        {button}
      </div>
      <Carousel pictures={defaultPics} />
    </div>
  );
};

export default Intro;
