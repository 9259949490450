import React from "react";
import css from "./SocialMedia.module.css";
import Platform from "./Platform";
import instagram from "./pictures/instagram.PNG";
import linkedin from "./pictures/linkedin.PNG";
import tiktok from "./pictures/tiktok.PNG";
import youtube from "./pictures/youtube.PNG";

const FollowMe = () => {
  const platforms = [
    {
      name: "youtube",
      url: "https://www.youtube.com/@squidslippers",
      img: {
        src: youtube,
        alt: "youtube logo",
      },
    },
    {
      name: "linkedin",
      url: "https://www.linkedin.com/in/jacob-moreno/",
      img: {
        src: linkedin,
        alt: "linkedin logo",
      },
    },
    {
      name: "tiktok",
      url: "https://www.tiktok.com/@squidslippers",
      img: {
        src: tiktok,
        alt: "tiktok logo",
      },
    },
    {
      name: "instagram",
      url: "https://www.instagram.com/squidslippers/",
      img: {
        src: instagram,
        alt: "instagram logo",
      },
    },
  ];
  return (
    <div className={css.grid}>
      {platforms.map((platform, index) => (
        <Platform key={index} platform={platform} />
      ))}
    </div>
  );
};

export default FollowMe;
