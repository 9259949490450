import React from "react";
import FollowMe from "./FollowMe";

const SocialMedia = () => {
  return (
    <div className="main">
      <FollowMe />
    </div>
  );
};

export default SocialMedia;
