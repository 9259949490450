import React from "react";
import { Link } from "react-router-dom";
import Intro from "../../components/intro/Intro";
import Accordion from "../../components/_accordion/Accordion";

const Shop = () => {
  return (
    <div className="main">
      <Intro
        primary="LET'S GO SHOPPING"
        secondary="if you're looking to buy art, creations, and other random things..."
        tertiary="you're in the right place."
      />
      <div className="column p01">
        <h2>AVAILABLE NOW</h2>
        <Accordion title="WIRE ART">
          <p className="o64">
            if you want customizable, hand-made charms, names, and other jewelry
            that i make out of wire...
          </p>
          <Link className="button" to="/sweepstakes/live">
            GET WIRE ART
          </Link>
        </Accordion>
        <Accordion title="COLORED PAINCIL">
          <p className="o64">
            if you want a self-defense device that can go with you everywhere,
            does some serious damage, and looks good doing it...
          </p>
          <Link className="button" to="/shop/colored-paincil">
            GET A COLORED PAINCIL
          </Link>
        </Accordion>
        <h2>COMING SOON</h2>
        <Accordion soon title="WOOD BURNING"></Accordion>
        <Accordion soon title="CLAY SCULPTURE"></Accordion>
        <Accordion soon title="PAINTING"></Accordion>
        <Accordion soon title="LOGO"></Accordion>
        <Accordion soon title="GRAPHIC DESIGN"></Accordion>
        <Accordion soon title="BUSINESS CARD"></Accordion>
        <Accordion soon title="HOLEY BLANKET"></Accordion>
        <Accordion soon title="RELEAFER"></Accordion>
        <Accordion soon title="CUSTOM SOFTWARE"></Accordion>
        <Accordion soon title="BUSINESS WEBSITE"></Accordion>
      </div>
    </div>
  );
};

export default Shop;
